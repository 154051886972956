// check url tele
export function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function formatVND(amount) {
  if (isNaN(amount)) {
    return 'Không phải là số';
  }

  const formattedAmount = amount.toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });

  return formattedAmount;
}

export const validateNumberRange = (rule, value, callback) => {
  const numberValue = parseInt(value, 10);

  if (isNaN(numberValue)) {
    callback('Vui lòng nhập một số!');
  } else if (numberValue < 15 || numberValue > 60) {
    callback('Chọn thời gian từ 15 đến 60 giây!');
  } else {
    callback(); // Giá trị hợp lệ
  }
};

export const debounce = (func, delay) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null;
    }, delay);
  };
};

export const formatIsFollow = (status) => {
  const color = {
    follow: '#389e0d',
    nofollow: '#ed0004',
    waiting: '#FD9900',
  }
  const text = {
    follow: 'Follow',
    nofollow: 'NoFollow',
    waiting: 'Waiting',
  }
  return {
    color: color[status],
    text: text[status]
  }
}

export const formatIsIndex = (status) => {
  const color = {
    index: '#389e0d',
    waiting: '#FD9900',
    noindex: '#ed0004',
    processing: '#2db7f5',
    fail: '#ed0004',
  }
  const text = {
    index: 'Index',
    waiting: 'Waiting',
    noindex: 'NoIndex',
    processing: 'Processing',
    fail: 'Fail',
  }
  return {
    color: color[status],
    text: text[status]
  }
}

export  const formatIndex = (status) => {
  const color = {
    index: '#87d068',
    noindex: '#ed0004',
    waiting: '#ff6911',
    fail: '#ed0004',
    processing: '#2db7f5',
  }
  const text = {
    index: 'INDEX',
    noindex: 'NOINDEX',
    waiting: 'CHƯA XỬ LÝ',
    fail: 'THẤT BẠI',
    processing: 'ĐÃ GỬI YÊU CẦU',
  }
  return {
    color: color[status],
    text: text[status]
  }
}