export const CONFIG_INDEX = {
  SYNBYTE: 'sinbyte',
  ONEHPING: '1hping',
  INDEXINJECT : 'indexinject',
  ONEINDEXER : '1indexer',
  GOOGLE : 'google',
}

export const INDEX_LINK = {
  INDEX: 'index',
  WAITING: 'waiting',
  NOINDEX: 'noindex',
  PROCESSING: 'processing',
  FAIL: 'fail',
  PROCESSING_CHECK_INDEX: 'processing_check_index',
}

export const APPROVE_STATUS = {
  waiting : 'Chờ duyệt',
  approved : 'Đã duyệt',
  rejected : 'Đã từ chối',
  requested : 'Chờ duyệt',
}

export const APPROVE_STATUS_ENUM = {
  WAITING : 'waiting',
  APPROVED : 'approved',
  REJECTED : 'rejected',
  REQUESTED : 'requested',
}