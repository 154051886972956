import React, {useState, useEffect, useCallback} from 'react';
import {
  Space,
  Select,
  Popconfirm,
  Button,
  Input,
  message,
  Form,
  Tag,
  Flex,
  Tooltip, Avatar,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import DataTable from '../../common/DataTable';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getWebsite,
  getSuggest,
  createSuggest,
  updateSuggest,
  deleteSuggese,
} from '../../../services/api';
import ModalFunction from './ModalFunction';
import { useParams } from 'react-router-dom';
import { formatVND } from '../../common/function';
import { debounce } from 'lodash';

const { Option } = Select;
let searchDebounceTimeout = null;

export default function SuggestDomain() {
  document.title = 'Danh sách đề xuất | CHECKLINK SERVICE OKVIP';
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams();

  const [domain, setDomain] = useState(null);
  const [listWebsite, setListWebsite] = useState([]);
  const [isModalFunction, setIsModalFunction] = useState(false);
  const [editData, setEditData] = useState();
  const [titleModal, setTitleModal] = useState('Thêm Đề Xuất');
  const [listSuggest, setListSuggest] = useState([]);
  const [search, setSearch] = useState('');
  //pagination
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  //gọi hàm call api domain
  useEffect(() => {
    fetchDataWebsite();
  }, []);

  //gọi hàm call api lấy ra danh sách suggest đựa vào domain
  useEffect(() => {
    if (domain) {
      fetchData(domain, '');
    }
  }, [domain]);

  //xử lý khi chuyển trang
  const onPageChange = (page, limit) => {
    setPage(page);
    setLimit(limit);

    onPageChangeAtSearch(page, limit);
  };

  //xử lý chuyển trang trong lúc search
  const onPageChangeAtSearch = async (page, limit) => {
    const params = {
      page: page,
      limit: limit,
      search: search,
    };

    const dataRes = await getAll(domain, params);
    setListSuggest(dataRes || []);
  };

  //hàm gọi api lấy ra danh sách suggest
  const fetchData = async (id, data) => {
    const resListSuggest = await getAll(id, data);

    setListSuggest(resListSuggest || []);
  };

  //hàm gọi api lấy ra danh sách domain để gắn vào select

  const fetchDataWebsite = async () => {
    try {
      const res = await getWebsite({ limit: 100000 });
      if (res?.data?.items?.length > 0) {
        const formatData = res?.data?.items.map(
          (item) => item?.website || item
        );
        setListWebsite(formatData);

        if (!domain) {
          setDomain(id ?? formatData[0]?._id);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.message);
    }
  };

  //gọi data ra table và xử lý converData
  const getAll = async (id, _prams) => {
    setLoading(true);
    try {
      const params = _prams
        ? _prams
        : {
            page: 1,
            limit: 10,
            search: '',
          };

      const res = await getSuggest(id, params);
      setTotalPage(res.data.pagination.total);
      const listWebsite = res?.data?.items;
      const data = convertDataTable(listWebsite);
      setLoading(false);
      return res?.data ? data : [];
    } catch (error) {
      message.error('Lỗi khi lấy danh sách domain:');
    } finally {
      setLoading(false);
    }
  };

  //hàm này xử lý khi bấm vào nút edit
  const onEdit = async (id) => {
    const dataEdit = listSuggest.filter((item) => item.key === id);
    setEditData(dataEdit[0]);
    setIsModalFunction(true);
    setTitleModal('Cập nhật Đề Xuất');
  };

  //Hàm xử lý khi bấm vào nút delete
  const onDelete = async (id) => {
    try {
      const resWebsite = await deleteSuggese(id);
      if (resWebsite?.statusCode === 200) {
        message.success('Xóa đề xuất thành công!');
        setSearch('');
        const updatedData = await getAll(domain, '');
        setListSuggest(updatedData);
      } else {
        message.error('Xóa đề Xuất thất bại!');
      }
    } catch (error) {
      console.log(error);
      message.error('Đã xảy ra lỗi, vui lòng thử lại!');
    }
  };

  //Conver data
  const convertDataTable = (dataArray) => {
    let data =
      dataArray?.length > 0 &&
      dataArray.map((item) => {
        return {
          key: item?._id,
          name: item?.name,
          linkSuggest: item?.linkSuggest,
          // telegramId: item?.telegramId,
          createName: item?.userId?.name,
          domain: item?.websiteId?.domain,
          totalMoney: item?.totalMoney,
          created_at: moment(item?.created_at).format('DD/MM/YYYY HH:mm'),
          status: item?.status,
          timer: item?.timer,
          guaranteed: item?.guaranteed,
        };
      });
    return dataArray ? data : [];
  };

  //Hàm này để xem thông tin chi tiết (đi tới detail)
  const showInfo = (id) => {
    navigate(`/detail/${id}`);
  };

  const onSearch = (value) => {
    setSearch(value);
    // Xóa timeout trước đó để đảm bảo chỉ thực hiện tìm kiếm sau cùng
    if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);

    // Thiết lập một timeout mới
    searchDebounceTimeout = setTimeout(async () => {
      const params = {
        page: 1,
        limit: 10,
        search: value,
      };
      // Gọi API với giá trị tìm kiếm sau khi chờ đợi 300ms
      const dataRes = await getAll(domain, params);
      setListSuggest(dataRes);
    }, 300); // Đợi 300ms sau khi người dùng ngừng gõ để thực hiện tìm kiếm
  };

  const handleRefresh = async () => {
    fetchData(domain, '');
    setSearch('');
  };

  //colunm của table
  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (_, text, index) => (page - 1) * limit + index + 1,
      width: 60,
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'name',
    },
    {
      title: 'Người tạo',
      dataIndex: 'createName',
      render: (_, { createName }) => {
        return createName ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#e5e5e5', color: '#000'}}>{createName.charAt(0)}</Avatar>
            <span className="ml-1">{createName}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Link đề xuất',
      dataIndex: 'linkSuggest',
      render: (_, { linkSuggest }) => {
        return (
          <a className='long-text' href={linkSuggest} target='_blank'>
            {linkSuggest}
          </a>
        );
      },
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      render: (_, { domain }) => {
        return (
          <a className='text-[#4096ff] ' href={domain} target='_blank'>
            {domain}
          </a>
        );
      },
    },
    // {
    //   title: 'Tổng tiền',
    //   dataIndex: 'totalMoney',
    //   render: (_, { totalMoney }) => <>{formatVND(totalMoney)} </>,
    // },
    {
      title: 'Thời gian bảo hành',
      dataIndex: 'guaranteed',
      render: (_, { guaranteed }) => {
        return moment(guaranteed).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
    },
    // {
    //   title: 'Telegram đối tác',
    //   dataIndex: 'telegramId',
    //   render: (_, { telegramId }) => {
    //     const displayTelegramId = telegramId.replace('https://t.me/', '');
    //     return (
    //       <a className='text-[#4096ff] ' href={telegramId} target='_blank'>
    //         {displayTelegramId}
    //       </a>
    //     );
    //   },
    // },
    {
      fixed: 'right',
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <Flex className='gap-2 items-center'>
          <Tooltip title='chi tiết'>
            <button
              onClick={() => showInfo(record.key)}
              className='bg-blue-600 action-button'
            >
              <EyeOutlined />
            </button>
          </Tooltip>
          <button
            onClick={() => onEdit(record.key)}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined />
          </button>

          <Popconfirm
            cancelButtonProps={{ className: 'bg-red-500 text-white btn-del' }}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Xóa Đề Xuất'
            description='Bạn muốn xóa đề xuất này?'
            onConfirm={() => onDelete(record.key)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  //hàm xử lý khi thay đổi domain trên select sẽ call api lại lại danh sách suggest
  const handleChangeWebsite = (id) => {
    navigate(`/suggest/${id}`);
    setDomain(id);
  };

  const onFinish = async (data) => {
    try {
      data.guaranteed = data.guaranteed.format('YYYY-MM-DD HH:mm:ss');
      data.linkSuggest = data.linkSuggest.startsWith('http')
        ? data.linkSuggest
        : 'https://' + data.linkSuggest;

      if (data?.telegramId) {
        if (data.telegramId.startsWith('https://t.me/')) {
          data.telegramId = data.telegramId;
        } else {
          data.telegramId = 'https://t.me/' + data.telegramId;
        }
      }
      let res;
      if (editData) {
        data.id = editData.key;
        res = await updateSuggest({ ...data, timer: Number(data.timer) });
        if (res.statusCode === 200) {
          fetchData(domain, '');
        }
      } else {
        data.websiteId = domain;
        res = await createSuggest({ ...data, timer: Number(data.timer) });
        if (res.statusCode === 200) {
          fetchData(domain, '');
        }
      }

      if (res.statusCode === 200) {
        message.success(
          editData ? 'Cập nhật đề xuất thành công!' : 'Thêm đề xuất thành công!'
        );
        setIsModalFunction(false);
        form.resetFields();
        setSearch('');
      } else {
        throw new Error(
          editData ? 'Cập nhật đề xuất thất bại!' : 'Thêm đề xuất thất bại!'
        );
      }
    } catch (error) {
      console.log(error);
      message.error('Đã xảy ra lỗi, vui lòng thử lại!');
    }
  };

  //Hàm này khi bấm vào nút thêm sẽ hiển thị modal
  const handleAdd = () => {
    setIsModalFunction(true);
    setEditData();
    form.resetFields();
    setTitleModal('Thêm Đề Xuất');
  };

  //Hàm xử lý export excel
  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Thêm dữ liệu vào worksheet
    worksheet.addRow([
      'Tên',
      'Người tạo',
      'Link đề Xuất',
      'Domain',
      'Tổng tiền',
      'Thời gian bảo hành',
      'Thời gian tạo',
      'Telegram đối tác',
    ]);
    listSuggest?.forEach((item) =>
      worksheet.addRow([
        item.name,
        item.createName,
        item.linkSuggest,
        item.domain,
        item.totalMoney,
        item.guaranteed,
        item.created_at,
        item.telegramId,
      ])
    );
    // Tạo một tệp Excel
    const buffer = await workbook.xlsx.writeBuffer();
    // Tải tệp Excel
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'suggest.xlsx';
    a.click();
  };

  return (
    <>
      <div className='flex gap-2 mt-5 items-start'>
        <Select
          placeholder='Chọn domain'
          showSearch
          style={{ width: 300, marginTop: '-20px', marginBottom: '20px' }}
          onChange={handleChangeWebsite}
          value={domain}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {listWebsite?.length > 0 &&
            listWebsite?.map((item) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item.domain}
                </Option>
              );
            })}
        </Select>
        <Space.Compact style={{ width: 300, marginTop: '-20px' }}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder='Nhập tên đề xuất hoặc telegram'
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            allowClear
          />
        </Space.Compact>
      </div>
      <div className='flex gap-2 mt-5 '>
        {domain && (
          <Button
            type='primary'
            className='add-btn flex items-center'
            onClick={handleAdd}
          >
            Thêm Đề Xuất
          </Button>
        )}
        <Button
          onClick={handleExport}
          className='btn-excel flex items-center'
          disabled={listSuggest?.length == 0}
        >
          Xuất Excel
        </Button>
        <Button
          onClick={handleRefresh}
          type='primary'
          className='add-reset flex items-center bg-slate-600 text-white'
          disabled={loading}
        >
          Reload
        </Button>
      </div>
      <DataTable
        listData={listSuggest}
        pageSize={limit}
        columns={columns}
        indexPage={page}
        totalPage={+totalPage}
        onPageChange={onPageChange}
        loading={loading}
        scroll={{ x: 1500 }}
      />

      <ModalFunction
        isModalFunction={isModalFunction}
        setIsModalFunction={setIsModalFunction}
        dataEdit={editData}
        onFinish={onFinish}
        title={titleModal}
        form={form}
      />
    </>
  );
}
