import React, {useEffect, useState} from 'react';
import {Modal, Divider, message, Tag} from 'antd';
import moment from 'moment';
import {isValidUrl} from '../../common/function';
import {useSelector} from 'react-redux';
import {getUserById} from '../../../services/api';

export default function ViewUser() {
  const idView = useSelector((state) => state.userSlice.idView);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataView, setDataView] = useState([]);

  useEffect(() => {
    if (idView) {
      fetchDataView()
      setIsModalOpen(true);
    }
  }, [idView]);

  const fetchDataView =  () => {
    getUserById(idView).then(async (res) => {
      if (res.status === true) {
        setDataView(res?.data);
      } else {
        message.error(res?.message);
      }
    }).catch(async (error) => {
      message.error(error.message);
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formatDate = (date) => moment(date).format('DD/MM/YYYY HH:mm');
  const modalInfoConfig = [
    {
      label: 'Tên:',
      text: dataView?.name,
    },
    {
      label: 'Email:',
      text: dataView?.email,
    },
    dataView?.leaderId
      ? {
        label: 'Leader Name:',
        text: dataView?.leaderId?.name,
      }
      : '',
    dataView?.leaderId
      ? {
        label: 'Leader Email:',
        text: dataView?.leaderId?.email,
      }
      : '',
    {
      label: 'Role:',
      text: dataView?.roleId?.description,
    },
    {
      label: 'Ngày tạo:',
      text: formatDate(dataView?.created_at),
    },
    {
      label: 'Trạng thái:',
      text: dataView?.status,
      isBadge: true,
    },
    {
      label: 'Telegram:',
      text: isValidUrl(dataView?.telegramId)
        ? new URL(dataView?.telegramId).pathname.split('/').pop()
        : dataView?.telegramId,
    },
  ];
  return (
    <div>
      <Modal
        footer={false}
        title={'Xem Thông Tin Tài Khoản'}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Divider/>
        {modalInfoConfig.map((item, index) =>
          item === '' ? null : (
            <div className={'modal-info'} key={index}>
              <span className='w-[40%]'>{item.label}</span>
              <span className='w-[60%]'>
                {item.isBadge ? (
                  <Tag color={item?.text === 'active' ? 'green' : 'red'} key={item?.text}>
                    { item?.text === 'active' ? item?.text?.toUpperCase() : 'BLOCK' }
                  </Tag>
                ) : (
                  item.text
                )}
              </span>
            </div>
          )
        )}
      </Modal>
    </div>
  );
}
