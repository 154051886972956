import React from "react";
import { Modal, Divider } from "antd";
import moment from "moment";

export default function ModalHistory({ showHistory, setShowHistory, data }) {
  const handleCancel = () => {
    setShowHistory(false);
    return;
  };

  const formattedDate = data?.linkId?.created_at
    ? moment(data?.linkId.created_at).format("DD/MM/YYYY HH:mm")
    : "";

  return (
    <div>
      <Modal
        footer={false}
        title="Thông tin"
        open={showHistory}
        onCancel={handleCancel}
      >
        <Divider />
        <div className="modal-info">
          <span className="w-[40%]">Team:</span>
          <span>{data?.leaderId?.name}</span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">User:</span>
          <span>{data?.userId?.name}</span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Link:</span>
          <span>{data?.linkId?.linkUrl}</span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Trạng thái Link:</span>
          <span>
            {
              data?.linkId
                ? (() => {
                  const responseJson = data.response ? JSON.parse(data.response) : null;
                  return responseJson?.status === "ok" ? "SUCCESS" : "FAIL";
                })()
                : ""
            }
          </span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Trạng thái:</span>
          <span>
            {
              (() => {
                const responseJson = data?.response ? JSON.parse(data?.response) : null;
                if (data?.linkId) {
                  return responseJson?.status;
                }
                return "";
              })()
            }
          </span>
        </div>
        <div className="modal-info">
          <span className="w-[40%]">Ngày tạo:</span>
          <span>{formattedDate}</span>
        </div>
      </Modal>
    </div>
  );
}
