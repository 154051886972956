import {Button, Checkbox, Col, Form, Modal, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {DownloadOutlined, ExportOutlined} from "@ant-design/icons";

const fills = [
  {label: 'URL', value: 'linkUrl'},
  {label: 'Từ khóa', value: 'keywords'},
  {label: 'Người tạo', value: 'createdBy'},
  {label: 'Ngày tạo', value: 'created_at'},
  {label: 'Ngày check', value: 'updated_at'},
  {label: 'isFollow', value: 'isFollow'},
  {label: 'isIndex', value: 'isIndex'},
  {label: 'Trạng thái check link', value: 'status'},
  {label: 'Trạng thái Index', value: 'indexed'},
]
const defaultValue = fills.map(item => item.value);

export default function ModalExportExcel(props) {
  const {
    show, handleCloseModalExportExcel, onFinish, submitModalExportExcel,
  } = props;

  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    if (show) {
      const defaultChecked = fills.map(item => item.value);
      setCheckedList(defaultChecked);
    }
  }, []);

  const onChange = (checkedValues) => {
    setCheckedList(checkedValues);
  };

  const handleExportExcel = (type) => {
    console.log(type);
    // call onFinish
    submitModalExportExcel({type, checkedList});
  }

  return (
    <>
      <Modal
        title="Xuất Excel"
        open={show}
        onCancel={() => handleCloseModalExportExcel()}
        footer={null}
        centered
        width={1000}
      >
        <Form
          onFinish={onFinish}
          autoComplete='on'
          layout='vertical'
        >
          <div>
            <h3 className='text-xxl font-semibold'>Chọn thông tin cần xuất:</h3>
          </div>

          <Checkbox.Group
            style={{
              width: '100%',
            }}
            onChange={onChange}
          >
            <Row gutter={[16, 16]}>
              {fills.map((item, index) => (
                <Col span={8} key={index}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>

          <div className='text-center mt-10'>
            <Space>
              <Button
                icon={ <DownloadOutlined/> }
                className='bg-[#16a34a] text-white border-[#16a34a]'
                onClick={() => handleExportExcel('all')}
              >
                Xuất toàn bộ
              </Button>
              <Button
                icon={ <DownloadOutlined/> }
                className='bg-[#16a34a] text-white border-[#16a34a]'
                onClick={() => handleExportExcel('filter')}
              >
                Xuất theo bộ lọc
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  )
}