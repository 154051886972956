import React, { useEffect, useState } from 'react';
import DataTable from '../../common/DataTable';
import {Space, Tag, Select, Button, message, Input, Avatar} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getHistory } from '../../../services/api';
import moment from 'moment';
import ModalHistory from './ModalHistory';
import ExcelJS from 'exceljs';
import { useLocation } from 'react-router-dom';

const { Option } = Select;
let searchDebounceTimeout = null;

export default function History() {
  document.title = 'Lịch sử thao tác | CHECKLINK SERVICE OKVIP';

  const location = useLocation();

  const searchParams = new URLSearchParams(location.searchTele);
  const telegramId = searchParams.get('telegramId');
  // const [telegram, setTelegram] = useState(telegramId || null);

  const [listHistory, setListHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [dataHistory, setDataHistory] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [param, setParam] = useState({
    page: 1,
    limit: 10,
    search: '',
    status: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [param]);

  const onPageChange = (page, limit) => {
    setParam({
      ...param,
      page,
      limit,
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getHistory(param);
      setTotalPage(res.data.pagination.total);
      setListHistory(res.data.items);
    } catch (error) {
      message.error('Lỗi khi lấy danh sách lịch sử! ');
    } finally {
      setLoading(false);
    }
  };

  const dataSource = listHistory?.map((item) => {
    return {
      key: item?._id,
      leader: item?.leaderId?.name ?? '',
      user: item?.userId?.name,
      link: item?.linkId?.linkUrl,
      created_at: moment(item?.created_at).format('DD/MM/YYYY HH:mm'),
      linkStatus: item?.status,
      // apiStatus: JSON.parse(item?.response)?.status,
      reason: item?.reason,
    };
  });

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (text, data, index) => index + 1,
      width: 60,
    },
    {
      title: 'Team',
      dataIndex: 'leader',
      render: (_, { leader }) => {
        return leader ? <Tag color={'gold'}> {leader}</Tag> : '';
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'user',
      render: (_, { user }) => {
        return user ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#e5e5e5', color: '#000'}}>{user.charAt(0)}</Avatar>
            <span className="ml-1">{user}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      render: (_, { link }) => {
        return (
          <a className='long-text' href={link} target='_blank'>
            {link}
          </a>
        );
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
    },

    {
      title: 'Trạng thái Link',
      dataIndex: 'linkStatus',
      render: (_, { linkStatus }) => (
        <Tag
          color={
            linkStatus === 'success'
              ? 'green'
              : linkStatus === 'waiting'
              ? 'orange'
              : 'red'
          }
        >
          {linkStatus === 'success'
            ? 'THÀNH CÔNG'
            : linkStatus === 'waiting'
            ? 'CHƯA XỬ LÝ'
            : 'THẤT BẠI'}
        </Tag>
      ),
    },
    { fixed: 'right', title: 'Lý do', dataIndex: 'reason' },
    // {
    //   title: "Thao tác",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle" className="gap-2">
    //       <button
    //         onClick={() => showInfo(record.key)}
    //         className="bg-blue-600 action-button"
    //       >
    //         <EyeOutlined />
    //       </button>
    //     </Space>
    //   ),
    // },
  ];

  const onSearchTele = (value) => {
    setParam({
      ...param,
      page: 1,
      search: value,
    });
  };

  const handleChange = (value) => {
    setParam({
      ...param,
      page: 1,
      status: value,
    });
  };

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Thêm dữ liệu vào worksheet
    worksheet.addRow([
      'Team',
      'Người tạo',
      'Link',
      'Thời gian tạo',
      'Telegram đối tác',
      'Trạng thái Link',
      'Trạng thái',
      'Lý do',
    ]);
    listHistory.forEach((item) => {
      worksheet.addRow([
        item.leader,
        item.user,
        item.link,
        item.created_at,
        item.telegramId,
        item.linkStatus,
        item.reason,
      ]);
    });

    // Tạo một tệp Excel
    const buffer = await workbook.xlsx.writeBuffer();

    // Tải tệp Excel
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'history.xlsx';
    a.click();
  };

  return (
    <>
      <ModalHistory
        data={dataHistory}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
      <div className='mt-5 flex gap-2'>
        <Space.Compact style={{ width: 300, marginTop: '-20px' }}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder='Tìm link, lí do...'
            allowClear
            onChange={(e) => onSearchTele(e.target.value)}
          />
        </Space.Compact>
        <Select
          className='w-[240px] mb-6 add-user-btn'
          onChange={handleChange}
          placeholder='Chọn trạng thái'
        >
          <Option value=''>Tất cả</Option>
          <Option value='success'>Thành công</Option>
          <Option value='waiting'>Đang xử lý</Option>
          <Option value='fail'>Thất bại</Option>
        </Select>
        <Button
          onClick={handleExport}
          className='btn-excel flex items-center'
          disabled={listHistory?.length === 0}
        >
          Xuất Excel
        </Button>
      </div>
      <DataTable
        listData={dataSource}
        pageSize={param.limit}
        columns={columns}
        indexPage={param.page}
        totalPage={+totalPage}
        onPageChange={onPageChange}
        loading={loading}
        scroll={{ x: 1500 }}
      />
    </>
  );
}
