import React, { useEffect, useState } from 'react';
import DataTable from '../../common/DataTable';
import { Popconfirm, message, Form, Input, Space, Tag } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import {
  getNotify,
  getNotifyById,
  DeleteNotiFyById,
} from '../../../services/api';
import moment from 'moment';

let searchDebounceTimeout = null;
export default function Notify() {
  document.title = 'Thông báo | CHECKLINK SERVICE OKVIP';
  const [listNotify, setListNotify] = useState([]);

  //pagination
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const onPageChange = (page, limit) => {
    setPage(page);
    setLimit(limit);

    onPageChangeAtSearch(page, limit);
  };

  const onPageChangeAtSearch = async (page, limit) => {
    const params = {
      page: page,
      limit: limit,
      status: search,
    };

    const dataRes = await getAllNotify(params, limit);
    setListNotify(dataRes);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getN = await getAllNotify();
    setListNotify(getN);
  };

  const getAllNotify = async (_prams) => {
    setLoading(true);
    try {
      const params = _prams
        ? _prams
        : {
            page: 1,
            limit: 10,
            status: search,
          };

      const res = await getNotify(params);
      setTotalPage(res.data.pagination.total);
      const notify = res?.data?.items;
      const data = convertDataTable(notify);
      setLoading(false);
      return res?.data ? data : [];
    } catch (error) {
      message.error('Lỗi khi lấy danh sách thông báo!');
    } finally {
      setLoading(false);
    }
  };

  const convertDataTable = (dataArray) => {
    let data =
      dataArray.length > 0 &&
      dataArray.map((item) => {
        return {
          key: item?._id,
          title: item?.title,
          description: item?.description,
          isRead: item?.isRead,
          created_at: moment(item?.created_at).format('DD/MM/YYYY HH:mm'),
          status: item?.status,
        };
      });
    return dataArray ? data : [];
  };

  const onDelete = async (id) => {
    try {
      const resNotify = await DeleteNotiFyById(id);
      if (resNotify?.statusCode === 200) {
        message.success('Xóa thông báo thành công!');

        const updatedData = await getAllNotify();
        setListNotify(updatedData);
      } else {
        message.error('Xóa thông báo thất bại!');
      }
    } catch (error) {
      message.error('Xóa thông báo thất bại!');
    }
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text, data, index) => index + 1,
      width: 80,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      render: (_, { description }) => {
        return <p className='description-text'>{description}</p>;
      },
    },
    // {
    //   title: "Xem",
    //   dataIndex: "isRead",
    //   render: (isRead) => {
    //     return isRead === 1 ? <Tag color={"green"}>Đã xem</Tag> : <Tag color={"red"}>Chưa xem</Tag>;
    //   },
    // },
    {
      width: 200,
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          cancelButtonProps={{ className: 'bg-red-500 text-white btn-del' }}
          okButtonProps={{
            className: 'bg-blue-600 text-white',
          }}
          title='Xóa thông báo'
          description='Bạn muốn xóa thông báo này?'
          onConfirm={() => onDelete(record.key)}
          okText='Yes'
          cancelText='No'
          okType=''
        >
          <button className='bg-red-600 action-button'>
            <DeleteOutlined />
          </button>
        </Popconfirm>
      ),
    },
  ];

  const onSearch = (value) => {
    setSearch(value);
    // Xóa timeout trước đó để đảm bảo chỉ thực hiện tìm kiếm sau cùng
    if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);

    // Thiết lập một timeout mới
    searchDebounceTimeout = setTimeout(async () => {
      const params = {
        page: 1,
        limit: 10,
        search: value,
      };
      // Gọi API với giá trị tìm kiếm sau khi chờ đợi 300ms
      const dataRes = await getAllNotify(params);
      setListNotify(dataRes);
    }, 300); // Đợi 300ms sau khi người dùng ngừng gõ để thực hiện tìm kiếm
  };

  return (
    <div>
      <div className='my-5 flex gap-2 '>
        <Space.Compact style={{ width: 300, marginTop: '-20px' }}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder='Tìm kiếm tiêu đề'
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            allowClear
          />
        </Space.Compact>
      </div>
      <DataTable
        listData={listNotify}
        pageSize={limit}
        columns={columns}
        indexPage={page}
        totalPage={+totalPage}
        onPageChange={onPageChange}
        // loading={loading}
      />
    </div>
  );
}
