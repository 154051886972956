import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getUsers } from '../../../services/api';

const ModalUser = (props) => {
  const {
    show,
    form,
    handleCloseModal,
    isEdit,
    submitModal,
    userId,
    title,
    role,
    changeRole,
  } = props;
  const { Option } = Select;
  const telegramIdRegExp = /^(?:https?:\/\/t\.me\/)?([A-Za-z0-9_]+)$/;
  const userRegExp = /^[\p{L}\s.'\d@-]{2,100}$/u;
  const [leader, setLeader] = useState([]);

  useEffect(() => {
    fetchLeader();
  }, []);

  // list leader
  const fetchLeader = async () => {
    try {
      let resData = await getUsers({ role: 'leader', limit: 0 });
      setLeader(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Modal
      title={title}
      open={show}
      onCancel={() => handleCloseModal()}
      footer={null}
    >
      <Divider />
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitModal}
        autoComplete='on'
      >
        <Form.Item
          label='Tên'
          name='name'
          rules={[
            {
              pattern: userRegExp,
              message: 'Tối thiểu 2 ký tự hoặc tối đa 100 ký tự!',
            },
            {
              required: true,
              message: 'Vui lòng điền tên!',
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              type: 'email',
              message: 'Nhập đúng định dạng Email!',
            },
            {
              required: isEdit !== true,
              message: 'Vui lòng điền Email!',
            },
          ]}
        >
          <Input
            allowClear
            disabled={isEdit === true}
            placeholder='Ex: user@okvip.com'
          />
        </Form.Item>
        {isEdit === true && !['admin', 'leader'].includes(userId) ? (
          ''
        ) : (
          <Form.Item
            label='Password'
            name='password'
            placeholder='Mật khẩu'
            rules={[
              {
                required: !isEdit,
                message: 'Vui lòng điền mật khẩu!',
              },
              { min: 6, message: 'Tối thiểu 6 kí tự!' },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item
          className='relative z-0 w-full mb-6 group'
          label='Phân quyền'
          name='role'
        >
          <Select
            onChange={changeRole}
            value={role}
            options={
              userId === 'leader'
                ? [{ value: 'user', label: 'User' }]
                : [
                    {
                      value: 'leader',
                      label: 'Leader',
                    },
                    {
                      value: 'admin',
                      label: 'Admin',
                    },
                    {
                      value: 'user',
                      label: 'User',
                    },
                  ]
            }
          />
        </Form.Item>
        {userId === 'leader' ? (
          ''
        ) : (
          <Form.Item
            className='relative z-0 w-full mb-6 group'
            label='Leader'
            name='leaderId'
          >
            <Select
              disabled={role !== 'user'}
              placeholder='Chọn team'
              allowClear
              showSearch
            >
              {leader?.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label='Telegram'
          name='telegramId'
          rules={[
            {
              required: true,
              message: 'Nhập telegram!',
            },
            {
              pattern: telegramIdRegExp,
              message: 'Telegram không hợp lệ!',
            },
          ]}
        >
          <Input allowClear placeholder='Không nhập @ (ex: user_okvip)' />
        </Form.Item>
        <div className='text-right mt-2'>
          <Space>
            <Button type='primary' danger onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button htmlType='submit' type='primary' className='btn-modal'>
              Xác nhận
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalUser;
