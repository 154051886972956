import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  message,
  Spin,
  List, Avatar,
} from 'antd';
import React, {useEffect, useState} from 'react';
import DataTable from '../../common/DataTable';
import {
  createPostLink,
  deleteLink,
  deleteLinkSelect, getDetailLink,
  getLink,
  putLink,
} from '../../../services/api';
import {useParams} from 'react-router-dom';
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  EditOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  LoadingOutlined, SyncOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {ManageLink} from './ManageLink';
import ModalLink from './ModalLink';
import {useDispatch, useSelector} from 'react-redux';
import {getIdLink, listLinkExcel} from '../../../redux/reducer';
import {INDEX_LINK} from "../../common/Enum";
import {formatIndex, formatIsFollow, formatIsIndex} from "../../common/function";

const ListLink = ({detailSuggest, socket, onSubmitRequestApproveSuccess}) => {
  const {suggestId} = useParams();
  const [form] = Form.useForm();
  const {confirm} = Modal;
  const dispatch = useDispatch();
  const {isFollow, isIndex, indexed, status, idLink} = useSelector(
    (state) => state.filterLinkSlice
  );
  const [dataLink, setDataLink] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [param, setParam] = useState({
    limit: 10,
    page: 1,
    search: '',
  });
  const [loading, setLoading] = useState(false);
  const [showModalLink, setShowModalLink] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalPage, setTotalPage] = useState('');
  const [isListNotEmpty, setIsListNotEmpty] = useState(false);
  const [listLinkShow, setListLinkShow] = useState({});
  const resultLinkExcel = useSelector(
    (state) => state.filterLinkSlice.resultLinkExcel
  );
  const [title, setTitle] = useState('');
  const [isShowModalHistory, setIsShowModalHistory] = useState(false);
  const [isLoadHistory, setIsLoadHistory] = useState(false);
  const [history, setHistory] = useState([])

  const duplicateLinksToCheck =
    listLinkShow?.duplicateLinks || resultLinkExcel?.duplicateLinks;
  const exitLinksToCheck =
    listLinkShow?.exitLinks || resultLinkExcel?.exitLinks;

  useEffect(() => {
    let timeoutId;
    if (param.search) {
      timeoutId = setTimeout(() => {
        fetchDataLink();
      }, 300);
    } else {
      fetchDataLink();
    }
    return () => clearTimeout(timeoutId);
  }, [param, isFollow, isIndex, indexed, status]);

  useEffect(() => {
    if (Object.keys(resultLinkExcel).length !== 0) {
      setListLinkShow({});
      setParam({...param, search: ''});
    }
  }, [resultLinkExcel]);

  useEffect(() => {
    if (Object.keys(listLinkShow).length !== 0) {
      const isEmptyListLinkShow = Object.values(listLinkShow).every(
        (array) => array.length === 0
      );
      setIsListNotEmpty(!isEmptyListLinkShow);
    } else {
      const isEmptyResultLink = Object.values(resultLinkExcel).every(
        (array) => array.length === 0
      );
      setIsListNotEmpty(!isEmptyResultLink);
    }
  }, [listLinkShow, resultLinkExcel]);

  useEffect(() => {
    if (isListNotEmpty) {
      setIsModalOpen(true);
    }
  }, [isListNotEmpty]);

  useEffect(() => {
    socket.on('updateStatusLink', (data) => {
      const linkId = data.linkId;
      const index = dataLink.findIndex((item) => item._id === linkId);
      if (index !== -1) {
        dataLink[index].indexed = data.indexed;
        setDataLink([...dataLink]);
      }
    });
  }, [dataLink]);

  const fetchDataLink = async () => {
    setLoading(true);
    const resListLink = await getLink({
      suggestId,
      limit: param.limit,
      page: param.page,
      search: param.search,
      isFollow: isFollow ?? '',
      isIndex: isIndex ?? '',
      indexed: indexed ?? '',
      status: status ?? '',
    });
    setTotalPage(resListLink?.data?.pagination?.total);
    setDataLink(resListLink?.data?.items);
    setLoading(false);
  };

  const dataSource = dataLink?.map((item) => ({
    key: item?._id,
    linkUrl: item?.linkUrl,
    keywords: item?.keywords,
    name: item?.userId.name,
    created_at: moment(item?.created_at).format('DD/MM/YYYY HH:mm'),
    updated_at: moment(item?.updated_at).format('DD/MM/YYYY HH:mm'),
    isFollow: item?.isFollow,
    isIndex: item?.isIndex,
    indexed: item?.indexed,
    status: item?.status,
    _id: item?._id,
  }));

  // change page
  const paginate = (pageNumber, limit) => {
    setParam({...param, page: pageNumber, limit: limit});
  };

  // search link
  const getValueSearch = (value) => {
    setParam({...param, page: 1, search: value});
  };

  // modal link
  const oppenModalLink = (data) => {
    setShowModalLink(true);
    if (data) {
      form.setFieldsValue({
        linkUrl: data.linkUrl,
        keywords: data?.keywords,
      });
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    setParam({...param, search: ''});
    setShowModalLink(false);
    dispatch(getIdLink(''));
    setTitle('');
  };

  const formatLink = (link) => {
    const trimmedLink = link.trim();
    return trimmedLink.startsWith('http') || trimmedLink.startsWith('www')
      ? trimmedLink
      : 'https://' + trimmedLink;
  };

  const formatKeywords = (keywords) => {
    return keywords?.map((item) => item.trim());
  };

  const createParam = (data) => {
    return {
      linkUrl: formatLink(data?.linkUrl),
      keywords: formatKeywords(data?.keywords),
    };
  };

  const splitAndFormatLinks = (linkStr) => {
    return linkStr.split(/[\n,]/).map((item) => formatLink(item.trim()));
  };

  const onFinish = async (data) => {
    try {
      if (idLink) {
        const param = createParam(data);
        const res = await putLink(idLink.key, param);
        if (res.status) {
          message.success('Cập nhật link thành công!');
          handleCloseModal();
        } else {
          message.error('Cập nhật thất bại.');
        }
      } else {
        const res = await createPostLink({
          suggestId: suggestId,
          linkUrl: splitAndFormatLinks(data.linkUrl),
          keywords: data?.keywords || [],
        });
        if (res?.statusCode === 200) {
          setListLinkShow({
            ...listLinkShow,
            duplicateLinks: res?.data?.duplicateLinks,
            exitLinks: res?.data?.exitLinks,
            invalidUrls: res?.data?.invalidUrls,
          });
          dispatch(listLinkExcel({}));
          message.success('Thêm link mới thành công!');
          handleCloseModal();
        } else {
          message.error('Thêm link mới thất bại, link không đúng định dạng :(');
        }
      }
    } catch (error) {
      message.error('Đã xảy ra lỗi trong quá trình xử lý.');
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsListNotEmpty(false);
  };

  // check link socket
  const handleCheckLinkSocket = (data) => {
    dataLink.map((item) => item._id === data.linkId && fetchDataLink());
  };

  // delete one link
  const conFirmDelete = async (data) => {
    const deleteLinkById = await deleteLink(data.key);
    if (deleteLinkById?.statusCode === 200) {
      message.success('Xóa link thành công!');
    } else {
      message.error('Xóa link thất bại!');
    }
    setParam({...param});
    setSelectedRowKeys([]);
  };

  const handleDeleteSelectRows = async () => {
    confirm({
      title: 'Xác nhận xóa?',
      content: `Bạn muốn xóa ${selectedRowKeys.length} link đã chọn?`,
      icon: <ExclamationCircleFilled/>,
      okText: 'Xóa ',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        handleDeleteMulti(selectedRowKeys);
      },
      onCancel() {
        handleCloseModal();
      },
      className: 'custom-btn-class',
    });
  };

  const handleDeleteMulti = async (selectedRowKeys) => {
    const res = await deleteLinkSelect({data: selectedRowKeys});
    if (res?.statusCode === 200) {
      message.success('Xóa link thành công!');
    } else {
      message.error('Xóa link thất bại!');
    }
    setSelectedRowKeys([]);
    fetchDataLink();
    setParam({...param, search: ''});
  };

  const limitText = (text, limit = 17) => {
    return text.length > limit ? text.slice(0, limit) + '...' : text;
  }

  const handleShowHistory = async (data) => {
    setIsShowModalHistory(true);
    setIsLoadHistory(true)
    try {
      const response = await getDetailLink(data._id);
      const histories = response?.data?.history ?? []
      setHistory(histories)
    } catch (e) {
      message.error('Đã xảy ra lỗi trong quá trình xử lý.');
    } finally {
      setIsLoadHistory(false)
    }
  }

  const handleCloseModalHistory = () => {
    setIsShowModalHistory(false);
  }

  const formatStatus = (status) => {
    const color = {
      success: 'green',
      cancel: 'red',
      waiting: 'orange',
    }
    const text = {
      success: 'Link Còn',
      cancel: 'Link Mất',
      waiting: 'Chưa Xử Lý',
    }
    return {
      color: color[status],
      text: text[status]
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (_, text, index) => (param.page - 1) * param.limit + index + 1,
      width: 60,
    },
    {
      width: 300,
      title: 'URL',
      dataIndex: 'linkUrl',
      render: (text) => (
        <a href={text} className='long-text' target='_blank' rel='noreferrer'>
          {text}
        </a>
      ),
    },
    {
      className: 'antd-cellCustom',
      width: 200,
      title: 'Từ khóa',
      dataIndex: 'keywords',
      render: (text) => {
        if (!text?.length) return '--';
        return text?.map((item) => (
          <p className={'cursor-pointer'} key={item}>
            <Tooltip title={item}>
              {limitText(item)}
            </Tooltip>
          </p>
        ));
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'name',
      render: (text) => {
        return text ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#e5e5e5', color: '#000'}}>{text.charAt(0)}</Avatar>
            <span className="ml-1">{text}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Ngày tạo',
      width: 130,
      dataIndex: 'created_at',
    },
    {
      title: 'Ngày check',
      width: 130,
      dataIndex: 'updated_at',
    },
    {
      title: () => (
        <>
          <p>isFollow</p>
          <p>isIndex</p>
        </>
      ),
      width: 120,
      dataIndex: 'isFollow',
      render: (text, _) => {
        const {isFollow, isIndex} = _;
        return (
          <>
            <p style={{color: formatIsFollow(isFollow).color}}>
              {formatIsFollow(isFollow).text}
            </p>
            <p style={{color: formatIsIndex(isIndex).color}}>
              {formatIsIndex(isIndex).text}
            </p>
          </>
        )
      }
    },
    {
      title: 'Trạng thái check link',
      dataIndex: 'status',
      width: 120,
      render: (text) => (
        <Tag color={formatStatus(text).color}>
          {formatStatus(text).text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Trạng thái Index',
      dataIndex: 'indexed',
      width: 120,
      render: (text) => {
        return (
          <>
            {text === INDEX_LINK.PROCESSING_CHECK_INDEX ? (
              <Button
                type='reset'
                size={'small'}
                className='processing-index flex items-center bg-slate-600 text-white'
                style={{margin: 0}}
                icon={<LoadingOutlined spin={loading}/>}
              >
                Đang xử lý
              </Button>
            ) : (
              <Tag color={formatIndex(text).color}>
                {formatIndex(text).text}
              </Tag>
            )}
          </>
        )
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 130,
      fixed: 'right',
      render: (text, data, index) => (
        <Space size='middle' className='gap-2'>
          <Tooltip title='Cập nhật link'>
            <Button
              onClick={() => {
                oppenModalLink(data);
                dispatch(getIdLink(data));
                setTitle('Cập Nhật Link');
              }}
              icon={<EditOutlined/>}
              shape='circle'
              className='bg-[#fd9900] action-button'
            />
          </Tooltip>
          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Xóa Link'
            description='Bạn muốn xóa link này?'
            onConfirm={() => conFirmDelete(data)}
            okText='Yes'
            cancelText='No'
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined/>
            </button>
          </Popconfirm>
          <Tooltip title='Lịch sử thao tác'>
            <button
              className='bg-slate-600 action-button'
              onClick={() => handleShowHistory(data)}
            >
              <ClockCircleOutlined/>
            </button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <ManageLink
        getValueSearch={getValueSearch}
        oppenModalLink={oppenModalLink}
        search={param.search}
        handleDeleteSelectRows={handleDeleteSelectRows}
        selectedRowKeys={selectedRowKeys}
        dataLink={dataLink}
        detailSuggest={detailSuggest}
        suggestId={suggestId}
        socket={socket}
        handleCheckLinkSocket={handleCheckLinkSocket}
        fetchDataLink={fetchDataLink}
        loading={loading}
        showModal={showModal}
        onSubmitRequestApproveSuccess={onSubmitRequestApproveSuccess}
      />
      {/* link list */}
      <DataTable
        scroll={{x: 1500}}
        listData={dataSource}
        pageSize={param.limit}
        columns={columns}
        indexPage={param.page}
        totalPage={+totalPage}
        onPageChange={paginate}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <ModalLink
        showModalLink={showModalLink}
        formAddLink={form}
        handleCloseModal={handleCloseModal}
        onFinish={onFinish}
        title={title}
      />

      <Modal
        title='Danh sách lịch sử thao tác'
        open={isShowModalHistory}
        onCancel={() => handleCloseModalHistory()}
        footer={null}
      >
        <div className={'min-h-[420px]'}>
          {isLoadHistory ? (
            <div className='w-full min-h-[420px] flex items-center justify-center'>
              <Spin/>
            </div>
          ) : (
            <div className={'overflow-y-auto h-[420px]'}>
              {!history?.length ? (
                <div className={'flex items-center justify-center min-h-[420px]'}>
                  <span className='opacity-40'>Không có lịch sử thao tác</span>
                </div>
              ) : (
                <>
                  {history?.map((item, index) => (
                    <div
                      key={index}
                      className={`list-item bg-white p-2 border-b border-gray-200`}
                    >
                      <div className='flex items-center'>
                        <div className='mr-4'>
                          <HistoryOutlined className={item.status === 'success' ? "text-[#16a34a]" : "text-[#cf1322]"}
                                           style={{fontSize: '25px'}}/>
                        </div>
                        <div className='text flex flex-col'>
                          <i className='name'>{moment(item?.created_at).format('DD/MM/YYYY HH:mm')}</i>
                          <i className={'capitalize whitespace-pre-line overflow-hidden text-ellipsis'}>{item.reason?.toLowerCase()}</i>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title={'Kiểm tra bộ link'}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Button
            key='ok'
            type='primary'
            onClick={handleCancel}
            className='bg-blue-600'
          >
            Ok
          </Button>
        }
      >
        <section>
          <p className='text-red-600'>
            List link trùng trong danh sách của bạn
          </p>
          {duplicateLinksToCheck?.map((item) => (
            <div>{item}</div>
          ))}
        </section>
        <section className='mt-3'>
          <p className='text-red-600'>List link đã tồn tại trong hệ thống</p>
          {exitLinksToCheck?.length > 0 && (
            <div className='h-[150px] overflow-auto rounded border p-2 scroollCustom'>
              {exitLinksToCheck?.map((item) => (
                <div>{item.linkUrl}</div>
              ))}
            </div>
          )}
        </section>
        <section className='mt-3'>
          <p className='text-red-600'>List link không hợp lệ</p>
          {(listLinkShow?.invalidUrls || resultLinkExcel?.invalidUrls)?.map(
            (item) => (
              <div>{item}</div>
            )
          )}
        </section>
      </Modal>
    </>
  );
};

export default ListLink;
