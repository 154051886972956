import {Badge, Button, Col, Flex, Row, Skeleton, Space, Tag, Tooltip} from 'antd';
import React, { useState } from 'react';
import { EditOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { formatVND, isValidUrl } from '../../common/function';
import moment from 'moment';
import {APPROVE_STATUS} from "../../common/Enum";

export const InfoSuggest = (props) => {
  const { oppenModalEdit, detailSuggest, title } = props;
  const [isClick, setIsClick] = useState(false);

  const tele = isValidUrl(detailSuggest?.telegramId)
    ? new URL(detailSuggest?.telegramId).pathname.split('/').pop()
    : detailSuggest?.telegramId;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setIsClick(true);
    setTimeout(() => {
      setIsClick(false);
    }, 1500);
  };

  const formatDate = (date) => moment(date).format('DD/MM/YYYY HH:mm');

  const configData = [
    {
      label: 'Mã đơn hàng:',
      value: (
        <span>
          {detailSuggest?.name}{' '}
          <Tooltip title='Sao chép mã đơn hàng'>
            <Button
              type='default'
              icon={!isClick ? <CopyOutlined/> : <CheckOutlined/>}
              onClick={() => copyToClipboard(detailSuggest?.name)}
              shape='circle'
              className='ml-3 text-white bg-slate-500 hover:bg-slate-600'
              size={'small'}
            />
          </Tooltip>
        </span>
      ),
    },
    {
      label: 'Ngày tạo:',
      value: formatDate(detailSuggest?.created_at),
    },

    {
      label: 'Domain:',
      value: (
        <a
          className='text-[#4096ff]'
          target='_blank'
          href={detailSuggest?.websiteId?.domain}
          rel='noreferrer'
        >
          {detailSuggest?.websiteId?.domain}
        </a>
      ),
    },
    {
      label: 'Bảo hành:',
      value: formatDate(detailSuggest?.guaranteed),
    },

    {
      label: 'Trạng thái:',
      value: (
        <Tag
          color={
            detailSuggest?.websiteId?.status === 'active' ? 'green' : 'red'
          }
        >
          {detailSuggest?.websiteId?.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      label: 'Tổng tiền index:',
      value: <>{formatVND(detailSuggest?.totalMoney)} </>,
    },
    {
      label: 'Người tạo:',
      value: <Tag color='geekblue'>{detailSuggest?.userId?.name}</Tag>,
    },
    {
      label: 'Tỉ lệ Indexed:',
      value: <>{detailSuggest?.rate?.rateIndexed || 0} %</>,
    },

    {
      label: 'Email:',
      value: detailSuggest?.userId?.email,
    },
    {
      label: 'Tỉ lệ isIndex:',
      value: <>{detailSuggest?.rate?.rateIsIndex || 0} %</>,
    },
    {
      label: 'Link đề xuất:',
      value: (
        <a
          href={detailSuggest?.linkSuggest}
          className='long-text'
          target='_blank'
          rel='noreferrer'
        >
          {detailSuggest?.linkSuggest}
        </a>
      ),
    },
    {
      label: 'Tỉ lệ isFollow:',
      value: <>{detailSuggest?.rate?.rateIsFollow || 0} %</>,
    },

    {
      label: 'Thời gian load link:',
      value:
        detailSuggest?.timer === 0
          ? 'Không giới hạn'
          : detailSuggest?.timer + 's',
    },
    {
      label: 'Tỉ lệ link còn:',
      value: <>{detailSuggest?.rate?.rateStatus || 0} %</>,
    },

    {
      label: 'Loại index:',
      value: <>{detailSuggest?.configIndexId?.name ? `${detailSuggest?.configIndexId?.code} (${detailSuggest?.configIndexId?.name})` : ''}</>,
    },
    {
      label: <>
        <div>
          <p>Yêu cầu duyệt index:</p>
          {detailSuggest?.approve?.status === 'waiting' && (
            <small className="text-red-600">Vui lòng báo TT/TP của bạn duyệt để có thể ép index đề xuất này nhé!</small>
          )}
        </div>
      </>,
      value: <>
        <Badge
          count={APPROVE_STATUS[detailSuggest?.approve?.status]}
          showZero
          color={
            detailSuggest?.approve?.status === 'waiting' ? 'orange' : detailSuggest?.approve?.status === 'approved' ? 'green' : (detailSuggest?.approve?.status === 'rejected' ? 'red' : '')
          }
        />
      </>,
    },
  ];
  const isShowTooltip = [
    'Tổng tiền index:',
    'Tỉ lệ Indexed:',
    'Tỉ lệ isIndex:',
    'Tỉ lệ isFollow:',
    'Tỉ lệ link còn:',
  ]

  const renderTitleTooltip = (title) => {
    const titleTooltip = {
      'Tổng tiền index:': 'Với 1 link index thành công sẽ tốn 400đ',
      'Tỉ lệ Indexed:': 'Tỉ lệ index thành công trên tổng số link',
      'Tỉ lệ isIndex:': 'Tỉ lệ link có isIndex = Index trên tổng số link',
      'Tỉ lệ isFollow:': 'Tỉ lệ link có isFollow = Follow trên tổng số link',
      'Tỉ lệ link còn:': 'Tỉ lệ link có Trạng thái check link = LINK CÒN trên tổng số link',
    }
    return titleTooltip[title]
  }

  return (
    <div className='border rounded-md'>
      <header className='p-3 flex justify-between '>
        <h1 className='text-2xl font-semibold'>{title}</h1>
        <button
          className='bg-[#fd9900] action-button '
          onClick={oppenModalEdit}
        >
          <EditOutlined className='text-lg' />
        </button>
      </header>
      <main className='m-4 mt-0'>
        <Skeleton loading={detailSuggest ? false : true}>
          <Row gutter={[8, 8]}>
            {configData.map((item, index) => (
              <Col span={12} key={index}>
                <section key={index} className='modal-info '>
                  <span className='w-[40%]'>
                    {isShowTooltip.includes(item.label) ? (
                      <Tooltip title={renderTitleTooltip(item.label)}>
                        <span className='cursor-pointer'>
                          {item.label}
                        </span>
                      </Tooltip>
                    ) : (
                      <> {item.label}</>
                    )}
                  </span>
                  <p className={item.label === 'Tổng tiền index:' ? 'text-red-600 w-[60%]' : 'w-[60%]'}>{item.value}</p>
                </section>
              </Col>
            ))}
          </Row>
        </Skeleton>
      </main>
    </div>
  );
};
