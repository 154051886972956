import {Button, Divider, Form, message, Modal, Space} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useState} from "react";
import {approveSuggest, updateApproveSuggest} from "../../../services/api";

export default function ModalReject(props) {
  const {
    show,
    handleCloseModal,
    form,
    submitModal,
    dataEdit,
  } = props;

  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsSubmitForm(true);
    try {
      const res = await updateApproveSuggest(dataEdit.suggest._id, {
        status: 'rejected',
        reason: values.reason,
      });
      if (res.status) {
        message.success('Từ chối đề xuất thành công');
        submitModal();
      } else {
        message.error(res?.message ?? 'Lỗi hệ thống');
      }
    } catch (e) {
      message.error('Lỗi hệ thống');
    } finally {
      setIsSubmitForm(false);
      handleCloseModal();
    }
  };

  return (
    <div>
      <Modal
        title='Từ chối đề xuất'
        open={show}
        onCancel={() => handleCloseModal()}
        footer={null}
        centered
      >
        <Divider/>
        <Form
          form={form}
          name='basic'
          labelCol={{
            span: 5,
            style: {textAlign: 'start'},
          }}
          style={{
            maxWidth: 700,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleFormSubmit}
          autoComplete='on'
        >
          <Form.Item
            label='Lý do'
            name='reason'
            rules={[
              {
                required: true,
                message: 'Nhập lý do từ chối!',
              },
            ]}
          >
            <TextArea allowClear placeholder='Nhập lý do từ chối'/>
          </Form.Item>

          <div className='text-right mt-10'>
            <Space>
              <Button htmlType='submit' type='primary' className='btn-modal' loading={isSubmitForm}>
                Xác nhận
              </Button>
              <Button type='primary' danger onClick={handleCloseModal}>
                Hủy
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  )
}