import './App.css';
import Login from './pages/auth/Login';
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import Layouts from './Layouts';
import ListSuggest from './pages/manager/Suggest';
import ListSite from './pages/manager/Site';
import Error from './Layouts/Error';
import History from './pages/manager/History';
import ManageUser from './pages/manager/User/ManageUser';
import Notify from './pages/manager/Notify';
import ErrorServer from './Layouts/ErrorServer';
import history from './utils/history';
import Error403 from './Layouts/Error403';
import DetailSuggest from './pages/manager/Link/DetailSuggest';
import Telegram from './pages/manager/Telegram/Telegram';
import io from 'socket.io-client';
import SuggestDomain from './pages/manager/Suggest/SuggestDomain';
import Content from './pages/manager/content/Content';
import ConfigIndex from "./pages/manager/ConfigIndex";
import ApproveSuggest from "./pages/manager/ApproveSuggest";
import DetailApproveSuggest from "./pages/manager/ApproveSuggest/detail";

const token = localStorage.getItem('accessToken');
const socket = io(process.env.REACT_APP_API_URL, {
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: `Bearer ${token} `,
      },
    },
  },
});

function App() {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/*' element={<MainLayout />} />
        <Route path='/error-server' element={<ErrorServer />}></Route>
      </Routes>
    </HistoryRouter>
  );
}

const MainLayout = () => {
  return (
    <Layouts>
      <Routes>
        <Route
          path='/'
          name='Users'
          element={<PrivateRoute element={<ManageUser />} />}
        />
        <Route
          path='telegram'
          name='Telegram'
          element={<PrivateRoute element={<Telegram />} />}
        />{' '}
        {/*<Route
          path='sinbyte'
          name='SinByte'
          element={<PrivateRoute element={<Sinbyte />} />}
        />*/}
        <Route
          path='config-index'
          name='Config Index'
          element={<PrivateRoute element={<ConfigIndex />} />}
        />
        <Route
          path='suggest'
          name='Suggest'
          element={<PrivateRoute element={<ListSuggest />} />}
        />
        <Route
          path='suggest/:id'
          name='Suggest'
          element={<PrivateRoute element={<SuggestDomain />} />}
        />
        <Route
          path='detail/:suggestId'
          name='Detail Suggest'
          element={<PrivateRoute element={<DetailSuggest socket={socket} />} />}
        />
        <Route
          path='domain'
          name='Domain'
          element={<PrivateRoute element={<ListSite />} />}
        />
        <Route
          path='approve-suggest'
          name='Approve Suggest'
          element={<PrivateRoute element={<ApproveSuggest />} />}
        />
        <Route
          path='approve-suggest/:id'
          name='Detail Approve Suggest'
          element={<PrivateRoute element={<DetailApproveSuggest />} />}
        />
        <Route
          path='content'
          name='Content'
          element={<PrivateRoute element={<Content />} />}
        />
        <Route
          path='histories'
          name='Histories'
          element={<PrivateRoute element={<History />} />}
        />
        <Route
          path='notifies'
          name='Notify'
          element={<PrivateRoute element={<Notify />} />}
        />
        <Route path={'*'} element={<Error />}></Route>
        <Route path='/error-403' element={<Error403 />}></Route>
      </Routes>
    </Layouts>
  );
};

export default App;
