import {findAllLinkByApproveSuggest} from "../../../services/api";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Badge, Flex, Input, message, Space, Tooltip} from "antd";
import moment from "moment/moment";
import {formatIndex, formatIsFollow, formatIsIndex} from "../../common/function";
import DataTable from "../../common/DataTable";
import {SearchOutlined} from "@ant-design/icons";

export default function ListLinkApproveSuggest(props) {
  document.title = 'Danh sách đề xuất | CHECKLINK SERVICE OKVIP';
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 1,
    search: '',
  });

  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.limit, pagination.search]);

  const dataSource = data?.map((item, index) => {
    return {
      key: item._id,
      indexed: item?.indexed,
      status: item?.status,
      created_at: item?.created_at ? moment(item?.created_at).format('DD/MM/YYYY HH:mm') : '--',
      updated_at: item?.updated_at ? moment(item?.updated_at).format('DD/MM/YYYY HH:mm') : '--',
      user: item?.userId ? item?.userId?.name : '--',
      suggest: item?.suggestId,
      keywords: item?.keywords,
      isFollow: item?.isFollow,
      isIndex: item?.isIndex,
      linkUrl: item?.linkUrl,
    }
  });

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (_, text, index) => (pagination.page - 1) * pagination.limit + index + 1,
      width: 60,
    },
    {
      width: 200,
      title: 'URL',
      dataIndex: 'linkUrl',
      render: (text) => (
        <Tooltip title={text}>
          <a href={text} className='long-text' target='_blank' rel='noreferrer'>
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'user',
      width: 170,
    },
    {
      title: 'Ngày tạo',
      width: 150,
      dataIndex: 'created_at',
    },
    {
      title: 'isFollow',
      dataIndex: 'isFollow',
      width: 120,
      render: (text) => {
        return (
          <Badge
            count={formatIsFollow(text).text}
            showZero
            color={renderColor(text)}
          />
        );
      },
    },
    {
      title: 'isIndex',
      dataIndex: 'isIndex',
      width: 120,
      render: (text) => {
        return (
          <Badge
            count={formatIsIndex(text).text}
            showZero
            color={renderColor(text)}
          />
        );
      },
    },
    {
      title: 'Trạng thái Index',
      dataIndex: 'indexed',
      width: 120,
      render: (text) => {
        return (
          <Badge
            count={formatIndex(text).text}
            showZero
            color={renderColor(text)}
          />
        )
      }
    },
  ];

  const fetchData = () => {
    setLoading(true);
    findAllLinkByApproveSuggest(id, pagination).then(async (res) => {
      if (!res?.status) {
        message.error(res?.message || 'Lỗi hệ thống');
      } else {
        setData(res?.data?.items);
        setPagination((prev) => ({
          ...prev,
          total: res.data.pagination.total,
        }))
      }
    }).catch(async (err) => {
      message.error('Lỗi hệ thống');
    }).finally(() =>
      setLoading(false)
    );
  }

  const onPageChange = (page, limit) => {
    setPagination((prev) => ({
      ...prev,
      page,
      limit,
    }));
  }

  const renderColor = (status) => {
    const color = {
      follow: 'green',
      nofollow: 'red',
      waiting: 'orange',
      index: 'green',
      noindex: 'red',
      processing: 'blue',
      fail: 'red',
      processing_check_index: 'blue',
    }
    return color[status];
  }

  const handleSearch = (value) => {
    setPagination((prev) => ({
      ...prev,
      search: value,
    }));
  }

  return (
    <>
      <Flex gap={'small'} className='mb-3' wrap='wrap'>
        <Space.Compact style={{ width: 300 }}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder='Tìm kiếm URL...'
            onChange={(e) => handleSearch(e.target.value)}
            allowClear
            value={pagination.search}
          />
        </Space.Compact>
      </Flex>
      <DataTable
        columns={columns}
        listData={dataSource}
        pageSize={pagination.limit}
        indexPage={pagination.page}
        totalPage={pagination.total}
        onPageChange={onPageChange}
        scroll={{x: 1500}}
      />
    </>
  )
}
