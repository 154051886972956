import ExcelJS from 'exceljs';
import moment from 'moment';
//export Excel
export const handleExport = async (listDatas, headerRowFile, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');
  // header workshit
  worksheet.addRow(headerRowFile);
  // add data to worksheet
  listDatas?.forEach((item, index) => {
    const rowData = headerRowFile.map((header) => {
      switch (header) {
        case 'STT':
          return index + 1;
        case 'Tên':
        case 'Người tạo':
          let userCreate = '';
          if (item?.userId?.name) {
            userCreate = item?.userId?.name + ' - ' + item?.userId?.email;
          }
          return userCreate;
        case 'Mã đơn hàng':
          return item?.name ?? item?.createdBy?.name;
        case 'Người thêm':
          return item?.userId?.name;
        case 'Link đề xuất':
          return item?.linkSuggest;
        case 'Domain':
          return item?.domain ?? item?.websiteId?.domain;
        case 'Email':
          return item.email;
        case 'Team':
          return item?.leaderId?.name ?? item?.leaderId;
        case 'Phân quyền':
          return item?.roleId?.description ?? item?.roleId;
        case 'Telegram':
          return item.telegramId;
        case 'Ngày tạo':
          return moment(item?.created_at)?.format('DD/MM/YYYY HH:mm');
        case 'Cập nhật':
          return item.updated_at;
        case 'Trạng thái':
          return item.status;
        case 'URL':
          return item.linkUrl;
        case 'Ngày check':
          return moment(item?.updated_at)?.format('DD/MM/YYYY HH:mm');
        case 'Is follow':
          return item.isFollow;
        case 'Is index':
          return item.isIndex;
        case 'Indexed':
          return item.indexed;
        case 'Trạng thái index': {
          const text = {
            index: 'index',
            noindex: 'noindex',
            waiting: 'chưa xử lý',
            fail: 'thất bại',
            processing: 'đã gửi yêu cầu',
          }
          return text[item.indexed];
        }
        case 'Trạng thái check link': {
          const text = {
            success: 'link còn',
            cancel: 'link mất',
            waiting: 'chưa xử lý',
          }
          return text[item.status];
        }
        case 'Từ khóa':
          return item?.keywords?.join(', ');
        default:
          return ''; // case undefined
      }
    });

    worksheet.addRow(rowData);
  });

  // create a file Excel
  const buffer = await workbook.xlsx.writeBuffer();

  // Download File Excel
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.click();
};
